<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<!--<v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="'Busqueda de ' + title"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>-->
			<v-card-text>
				<v-card outlined>
					<v-card-subtitle>
						<v-btn
							:color="$cv('principal')"
							@click="showPanel = !showPanel"
							text
							:title="
								!showPanel
									? 'Abrir panel de Consulta de Usuarios'
									: 'Cerrar panel de Consulta de Usuarios'
							"
							class="text-left"
						>
							Consulta de Usuarios
						</v-btn>
					</v-card-subtitle>
					<v-expand-transition>
						<v-card-text v-show="showPanel">
							<v-form ref="formBusqueda" v-model="valid" lazy-validation>
								<v-row dense>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-model="formSearch.name"
											label="Usuario"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-model="formSearch.email"
											label="Email"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<!--
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      :items="agencias"
                      item-value="id"
                      item-text="detalle"
                      label="Agencia"
                      v-model="formSearch.agencia_id"
                    ></v-autocomplete>
                  </v-col>
                  -->
									<v-col cols="12" md="4" sm="12">
										<v-menu
											ref="menuFecha"
											v-model="menuFecha"
											:close-on-content-click="false"
											:return-value.sync="date"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="dateRangeText"
													label="Ultimo ingreso"
													readonly
													v-bind="attrs"
													v-on="on"
												></v-text-field>
											</template>
											<v-date-picker v-model="dates" range no-title scrollable>
												<v-spacer></v-spacer>
												<v-btn
													text
													:color="$cv('principal')"
													@click="menuFecha = false"
												>
													Cancelar
												</v-btn>
												<v-btn
													text
													:color="$cv('principal')"
													@click="$refs.menuFecha.save(date)"
												>
													OK
												</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" md="12" sm="12" justify="end" align="right">
										<v-btn
											:color="$cv('principal')"
											small
											@click="
												page = 1;
												getUsuarios();
											"
										>
											Buscar
										</v-btn>
										<v-btn :color="$cv('btnEliminar')" small @click="reset">
											Reset
										</v-btn>
									</v-col>
								</v-row>
								<v-container fill-height fluid>
									<v-row align="center" justify="center">
										<v-col></v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
					</v-expand-transition>
				</v-card>
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					class="elevation-1"
					:options.sync="pagination"
					:server-items-length="total"
					:footer-props="footerProps"
				>
					<template v-slot:top>
						<v-toolbar flat color="white">
							<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
							<v-spacer></v-spacer>
							<v-dialog v-model="dialogLogins" max-width="70%" persistent>
								<v-card>
									<v-toolbar dark :color="$cv('principal')">
										<v-btn icon dark @click="dialogLogins = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-toolbar-title>{{ titleToolbar }}</v-toolbar-title>
									</v-toolbar>
									<v-card-text style="overflow-y: scroll; max-height: 92vh">
										<v-container>
											<v-row dense>
												<v-col cols="12" md="3" sm="12">
													<v-card
														class="mx-auto"
														max-height="80%"
														max-width="80%"
													>
														<v-img
															src="images/default-user-image.png"
															class="profile"
															dark
														>
															<v-row class="fill-height">
																<v-spacer></v-spacer>
															</v-row>
														</v-img>
														<v-container style="min-height: 180px">
															<v-list-item>
																<v-list-item-content>
																	<v-list-item-title
																		class="text-h8"
																		v-text="titleNombre"
																	></v-list-item-title>
																	<v-list-item-subtitle
																		class="text-h8"
																		v-text="titleAgencia"
																	></v-list-item-subtitle>
																	<v-list-item-subtitle
																		class="text-h8"
																		v-text="titleEstado"
																	></v-list-item-subtitle>
																</v-list-item-content>
															</v-list-item>
														</v-container>
													</v-card>
												</v-col>
												<v-col cols="12" md="9" sm="12">
													<v-card>
														<v-card-title>
															<div
																class="text-overline mb-4 primary text-white"
															>
																Intentos de Inicio de Sesion
															</div>
														</v-card-title>
														<v-data-table
															:headers="headersLoginAttempts"
															:items="loginAttempts"
															:search="searchLogins"
															sort-by="created_at"
															sort-desc
															class="elevation-1"
															:item-class="itemRowBackground"
															:items-per-page="itemsPerPageLogin"
														>
															<template v-slot:top>
																<v-text-field
																	v-model="searchLogins"
																	append-icon="mdi-magnify"
																	label="Busqueda de Intentos de Login"
																	single-line
																	hide-details
																	class="mx-4"
																></v-text-field>
															</template>
															<template v-slot:[`item.failed`]="{ item }">
																<span>{{
																	item.failed == "No" ? "Exitoso" : "Fallido"
																}}</span>
															</template>
														</v-data-table>
													</v-card>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
								</v-card>
							</v-dialog>
							<v-dialog v-model="dialog" persistent max-width="90%">
								<template v-if="visible" v-slot:activator="{ on, attrs }">
									<v-btn
										:color="$cv('principal')"
										dark
										class="mx-2"
										fab
										v-bind="attrs"
										v-on="on"
										><v-icon blue>mdi-plus</v-icon></v-btn
									>
								</template>
								<v-card>
									<v-form ref="form" v-model="valid" lazy-validation>
										<v-toolbar dark :color="$cv('principal')">
											<v-btn icon dark @click="dialog = false">
												<v-icon>mdi-close</v-icon>
											</v-btn>
											<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
											<v-spacer></v-spacer>
											<v-toolbar-items>
												<v-btn dark text @click="save" :disabled="!valid"
													>Guardar</v-btn
												>
											</v-toolbar-items>
										</v-toolbar>
										<v-card-text style="overflow-y: scroll; max-height: 92vh">
											<v-container>
												<v-row>
													<v-col cols="12" md="6" sm="6">
														<v-text-field
															v-model="editedItem.email"
															label="Email"
															:rules="emailRules"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="6" sm="6">
														<v-text-field
															v-model="editedItem.name"
															label="Nombre y Apellido"
															:rules="rulesRequired"
														></v-text-field>
													</v-col>
												</v-row>

												<v-row>
													<v-col cols="12" md="12" sm="12">
														<v-autocomplete
															:items="roles"
															v-model="editedItem.roles"
															item-value="id"
															item-text="name"
															chips
															label="Roles"
															multiple
															outlined
															return-object
														>
														</v-autocomplete>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="6" sm="6">
														<v-text-field
															type="password"
															v-model="editedItem.password"
															label="Contraseña"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="6" sm="6">
														<v-autocomplete
															:items="estados"
															item-value="id"
															item-text="detalle"
															label="Estado"
															v-model="editedItem.status"
															:rules="rulesRequired"
														></v-autocomplete>
													</v-col>
													<!--
                          <v-col cols="12" md="6" sm="6" class="pa-2">
                            <v-autocomplete
                              :items="agencias"
                              item-value="id"
                              item-text="detalle"
                              label="Agencia"
                              v-model="editedItem.agencia_id"
                              :disabled="disableAgencia"
                              :rules="!disableAgencia ? rulesRequired : []"
                            ></v-autocomplete>
                          </v-col>
                          -->
												</v-row>
												<!--
                        <v-row>
                          <v-col cols="12" md="6" sm="6" class="pa-2">
                            <v-text-field
                              v-model="editedItem.telefono"
                              label="Telefono"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6" sm="6" class="pa-2">
                            <v-text-field
                              v-model="editedItem.celular"
                              label="Telefono Celular"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        -->
											</v-container>
										</v-card-text>
									</v-form>
								</v-card>
							</v-dialog>
						</v-toolbar>
					</template>
					<template v-slot:[`item.lastlogin`]="{ item }">
						<span>{{
							item.lastlogin ? new Date(item.lastlogin).toLocaleString() : ""
						}}</span>
					</template>

					<template v-slot:[`item.status`]="{ item }">
						<div :class="getAlertaIndicador2(item, 'status')" dark>
							{{ item.status }}
						</div>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-icon
							v-if="visible"
							small
							class="mr-2"
							color="blue"
							title="Editar"
							@click="editItem(item.id)"
						>
							mdi-pencil
						</v-icon>
						<v-icon
							v-if="!item.status_id || item.status_id == '1'"
							small
							:color="$cv('btnEditar')"
							title="Bloquear Usuario"
							@click="changeStatus(item.id, 2, 'Bloquear Usuario')"
						>
							mdi-account-lock
						</v-icon>
						<v-icon
							v-if="item.status_id == '2' || item.status_id == '3'"
							small
							:color="$cv('btnEditar')"
							title="Desbloquear Usuario"
							@click="changeStatus(item.id, 1, 'Desbloquear Usuario')"
						>
							mdi-account-convert
						</v-icon>
						<v-icon
							v-if="visible"
							small
							:color="$cv('btnEliminar')"
							title="Eliminar"
							@click="deleteItem(item.id)"
						>
							mdi-delete
						</v-icon>
						<v-icon
							small
							color="yellow"
							title="Imprimir"
							@click="getLoginAttempts(item)"
						>
							mdi-file-document
						</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
		<download-excel
			class="btn btn-rounded btn-primary"
			:fields="json_fields"
			:fetch="fetchData"
			:type="dataExportType"
			:name="'report-Usuarios.' + dataExportType"
		>
			Exportar Excel
		</download-excel>
	</v-container>
</template>
<style>
.style-1 {
	color: green;
}
.style-2 {
	color: red;
}
</style>
<script>
	function title() {
		return "Usuario";
	}

	export default {
		data: (vm) => ({
			disableAgencia: true,
			formSearch: {},
			dates: [],
			date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			pagination: {},
			menuFecha: false,
			total: 0,
			loading: true,
			footerProps: { "items-per-page-options": [5, 10, 15, 30] },
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			baseUrl: "/api/users",
			baseUrlAdmin: "/api/admin_users",
			urlExport: "/api/users-export",
			urlExportAdmin: "/api/admin_users_export",
			showPanel: false,
			dataExportType: "xls",
			json_fields: {
				Usuario: "name",
				Email: "email",
				/*Agencia: "agencia",*/
				Roles: "roles_asignados",
				"Ultimo Ingreso": "lastlogin",
				"Inactividad(dias)": "difference",
				Fecha: "created_at",
			},
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			dialogLogins: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
			emailRules: [
				(v) =>
					!v ||
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
					"Ingrese un mail válido",
			],
			search: "",
			searchLogins: "",
			rulesRequired: [(v) => !!v || "Requerido"],
			users: false,
			headers: [
				/*{
				        text: "Id",
				        align: "start",
				        sortable: false,
				        value: "id",
				      },*/
				{ text: title(), filterable: true, value: "name" },
				{ text: "Email", filterable: true, value: "email" },

				/*{ text: "Agencia", filterable: true, value: "agencia" },*/
				{ text: "Roles", filterable: true, value: "roles_asignados" },
				{ text: "Ultimo Ingreso", value: "lastlogin" },
				{ text: "Inactividad(días)", value: "difference" },
				// { text: "Creado", value: "created_at" },
				{ text: "Estado", filterable: true, value: "status" },
				{ text: "Accciones", value: "actions", sortable: false },
			],
			estados: [
				{ id: "1", detalle: "Activo" },
				{ id: "2", detalle: "Inactivo" },
				{ id: "3", detalle: "Bloqueado" },
			],
			desserts: [],
			headersLoginAttempts: [
				{ text: "Fecha y Hora", align: "start", value: "created_at" },
				{ text: "IP", value: "ip_address" },
				{ text: "Estado", value: "failed" },
			],
			itemsPerPageLogin: 4,
			loginAttempts: [],
			titleToolbar: "",
			titleNombre: "",
			titleAgencia: "",
			titleEstado: "",
			editedIndex: -1,
			editedItem: {
				id: "",
				name: "",
				email: "",
				password: "",
				status: "",
				//agencia_id: "",
				telefono: "",
				celular: "",
				responsable: "",
				roles: [],
			},
			//agencias: [],
			itemsSN: [
				{ id: "S", detalle: "SI" },
				{ id: "N", detalle: "NO" },
			],
			roles: "",
		}),

		computed: {
			dateRangeText: {
				get: function () {
					return this.dates.join(" ~ ");
				},
				set: function (date) {
					return date;
				},
			},
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: "Editar " + this.title;
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
			pagination: {
				handler() {
					this.getUsuarios();
				},
				deep: true,
			},
		},

		created() {
			this.getUsuarios();
			this.getUsers();
			//this.getAgencias();
			this.getRoles();
		},

		methods: {
			/*isAgencia() {
				      let options = "";
				      options = this.editedItem.roles.find((o) => {
				        return o.name.toUpperCase() === "AGENCIA";
				      });
				      if (options) {
				        this.disableAgencia = false;
				      } else {
				        this.editedItem.agencia_id = "";
				        this.disableAgencia = true;
				      }
				    },*/
			async fetchData() {
				let query = this.formSearch;
				let formData = new FormData();
				formData = this.getQryParams(query);
				let queryParams = new URLSearchParams(formData).toString();
				let url = this.urlExport;
				if (this.$store.state.user.role_id == 1) {
					url = this.urlExportAdmin;
				}
				const response = await axios.get(url + "?" + queryParams);
				return response.data.data;
			},
			reset() {
				this.$refs.formBusqueda.reset();
				this.getUsuarios();
			},
			itemRowBackground: function (item) {
				return item.failed == "No" ? "style-1" : "style-2";
			},
			getAlertaIndicador2(value, item) {
				if (!value) return "white";
				let verde = "grid-green rounded-pill text-center p-1 ml-5 mr-5";
				let amarillo = "grid-yellow rounded-pill text-center p-1 ml-5 mr-5";
				let rojo = "grid-red rounded-pill text-center p-1 ml-5 mr-5";

				switch (item) {
					case "status":
						if (value.status == "Activo") return verde;
						if (value.status == null) return "white";
						else return rojo;
						break;
				}
			},

			/*getAgencias() {
				      let url = "api/users_agencias";
				      if (this.$store.state.user.role_id == 1) {
				        url = "api/admin_agencias";
				      }
				      axios
				        .get(url)
				        .then((response) => {
				          this.agencias = response.data;
				        })
				        .catch((response) => {
				          this.snackbar = true;
				          this.text = "Error al obtener agencias. Error: " + response.message;
				          this.color = "error";
				        });
				    },*/
			getRoles() {
				let url = "api/roles";
				if (this.$store.state.user.role_id == 1) {
					url = "api/list_roles";
				}
				axios
					.get(url)
					.then((response) => {
						this.roles = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error obtener roles. Error: " + response.message;
						this.color = "error";
					});
			},
			addFooters(doc) {
				const pageCount = doc.internal.getNumberOfPages();

				doc.setFont("helvetica", "italic");
				doc.setFontSize(8);
				for (var i = 1; i <= pageCount; i++) {
					doc.setPage(i);
					doc.text("Fleet | Fleet", 10, 287, {
						align: "left",
					});
					doc.text("Página " + String(i) + " de " + String(pageCount), 200, 287, {
						align: "right",
					});
				}
			},

			formatDate(date) {
				if (!date) return null;

				const [year, month, day] = date.split("-");
				return `${day}/${month}/${year}`;
			},

			capitalize(s) {
				if (typeof s !== "string") return "";
				return s.charAt(0).toUpperCase() + s.slice(1);
			},
			getQryParams(dataSearch) {
				let formData = new FormData();

				if (dataSearch.email) {
					formData.append("email", dataSearch.email);
				}
				if (dataSearch.name) {
					formData.append("name", dataSearch.name);
				}
				/*if (dataSearch.agencia_id) {
				        formData.append("agencia_id", dataSearch.agencia_id);
				      }*/
				if (dataSearch.difference) {
					formData.append("difference", dataSearch.difference);
				}
				if (dataSearch.status) {
					formData.append("status", dataSearch.status);
				}
				if (this.dates.length > 0) {
					formData.append("latest_login_start", this.dates[0]);
					formData.append("latest_login_end", this.dates[1]);
				}

				return formData;
			},
			getUsuarios() {
				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
				let url = this.baseUrl;
				if (this.$store.state.user.role_id == 1) {
					url = this.baseUrlAdmin;
				}
				if (this.formSearch && !sortBy) {
					let formData = new FormData();
					formData = this.getQryParams(this.formSearch);
					let query = new URLSearchParams(formData).toString();
					axios
						.get(
							url +
								"?" +
								query +
								"&page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage
						)
						.then((r) => {
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text = "Error al obtener denuncias. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));

					return;
				}

				if (sortBy && !this.formSearch) {
					let direction = sortDesc[0] == true ? "desc" : "asc";
					axios
						.get(
							url +
								"?direction=" +
								direction +
								"&sortBy=" +
								sortBy +
								"&page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage
						)
						.then((r) => {
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text =
								"Error al obtener planillas del personal. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));
				}

				if (this.formSearch && sortBy) {
					let formData = new FormData();
					formData = this.getQryParams(this.formSearch);
					let query = new URLSearchParams(formData).toString();
					let direction = sortDesc[0] == true ? "desc" : "asc";
					axios
						.get(
							url +
								"?" +
								query +
								"&page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage +
								"&sortBy=" +
								this.pagination.sortBy +
								"&direction=" +
								direction
						)
						.then((r) => {
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.loading = false;
							this.snackbar = true;
							this.text = "Error al obtener solicitudes. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));

					return;
				}

				if (!this.formSearch && !sortBy) {
					axios
						.get(
							url +
								"?page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage
						)
						.then((r) => {
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text =
								"Error al obtener planillas del personal. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));
				}
			},
			getLoginAttempts(item) {
				let url = "api/userLoginAttempts/";
				if (this.$store.state.user.role_id == 1) {
					url = "api/userAdminLoginAttempts/";
				}
				axios
					.get(url + item.email)
					.then((r) => {
						this.titleToolbar = "Auditoria de Usuario: " + item.email;
						this.titleNombre = "Nombre: " + item.name;
						//this.titleAgencia = item.agencia ? "Agencia: " + item.agencia : "";
						this.titleEstado =
							"Estado: " + (item.status ? item.status : "Activo");
						this.loginAttempts = r.data;
						this.dialogLogins = true;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},

			editItem(id) {
				let url = this.baseUrl;
				if (this.$store.state.user.role_id == 1) {
					url = this.baseUrlAdmin;
				}
				axios
					.get(url + "/" + id)
					.then((r) => {
						this.editedIndex = r.data.id;
						delete r.data.id;
						this.editedItem = Object.assign({}, r.data);

						//this.isAgencia();
						this.dialog = true;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},

			deleteItem(id) {
				let url = this.baseUrl;
				if (this.$store.state.user.role_id == 1) {
					url = this.baseUrlAdmin;
				}
				this.$confirm({
					message: `Eliminar Registro?`,
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							axios.delete(url + "/" + id).then(
								(response) => {
									this.snackbar = true;
									this.text = "Registro Eliminado";
									this.getUsuarios();
								},
								(error) => {
									this.snackbar = true;
									this.text = "Error al eliminar la planilla Error: " + e.message;
									this.color = "error";
								}
							);
						}
					},
				});
			},
			changeStatus(id, status, msg) {
				let url = "/api/changeStatusUser/";
				if (this.$store.state.user.role_id == 1) {
					url = "/api/changeStatusAdminUser/";
				}
				this.$confirm({
					message: msg + "?",
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							axios.post(url + id + "/" + status).then(
								(response) => {
									this.snackbar = true;
									this.text = "Operacion Exitosa";
									this.getUsuarios();
								},
								(error) => {
									this.snackbar = true;
									this.text =
										"Error al ejecutar la operacion. Error: " + e.message;
									this.color = "error";
								}
							);
						}
					},
				});
			},

			close() {
				this.dialog = false;
				this.valid = true;
				this.resetValidation();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			resetValidation() {
				this.$refs.form.resetValidation();
			},

			save() {
				if (this.$refs.form.validate()) {
					if (this.editedIndex > -1) {
						let formData = new FormData();
						let url = this.baseUrl + "/" + this.editedIndex;
						if (this.$store.state.user.role_id == 1) {
							url = this.baseUrlAdmin + "/" + this.editedIndex;
						}
						formData.append("_method", "PUT");

						axios
							.post(url, {
								_method: "PUT",
								data: this.editedItem,
							})
							.then((r) => {
								if (r.data.status == 200) {
									this.snackbar = true;
									this.text = "Registro Actualizado";
									this.color = "success";
									this.getUsuarios();
								} else {
									this.snackbar = true;
									this.text =
										"Error al actualizar los datos. Error: " + r.data.data;
									this.color = "error";
								}
							})
							.catch((e) => {
								this.snackbar = true;
								this.text = "Error al actualizar los datos. Error: " + e.message;
								this.color = "error";
							});
					} else {
						let method = "post";
						let url = this.baseUrl;
						if (this.$store.state.user.role_id == 1) {
							url = this.baseUrlAdmin;
						}
						axios({
							method,
							url,
							data: this.editedItem,
						})
							.then((r) => {
								this.snackbar = true;
								this.text = "Registro Creado";
								this.color = "success";
								this.getUsuarios();
							})
							.catch((e) => {
								console.log(e);
								this.snackbar = true;
								this.text = "Error al ingresar los datos. Error: " + e.message;
								this.color = "error";
							});
					}

					this.close();
				} else {
					this.snackbar = true;
					this.text = "Complete los campos obligatorios";
					this.color = "error";
				}
			},
			getUsers() {
				let url = this.baseUrl;
				if (this.$store.state.user.role_id == 1) {
					url = this.baseUrlAdmin;
				}
				axios
					.get(url)
					.then((response) => {
						this.users = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al cargar usuarios. Error: " + response.message;
						this.color = "error";
					});
			},
		},
		mounted() {
			console.log("Componente Usuarios creado");
		},
	};
</script>
